import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import logo from "../static/logo.jpg";

const Logo = styled.img`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  border: 1px solid #d8d8d8;
  padding: 20px;
  width: 100%;

  p {
    font-size: 1.4em;
  }
`;

const redirectCopy = "We are redirecting you back to your referrer to sign in.";

const successCopy =
  "Thanks for creating your account. You may close this window now.";

const Success = ({ redirect = false, redirectUrl }) => (
  <Content>
    <Logo src={logo} alt="Ag Connections Logo" />
    <center>
      <h2>Success!</h2>
    </center>
    <hr />
    <div>
      <p>You&apos;ve successfully created your account.</p>
      <p>{redirect ? redirectCopy : successCopy}</p>
      {redirect && redirectUrl !== "Desktop" && (
        <p>
          Alternatively, you can click the following link:
          <a href={redirectUrl}>{redirectUrl}</a>
        </p>
      )}
    </div>
  </Content>
);

Success.propTypes = {
  redirect: PropTypes.bool,
  redirectUrl: PropTypes.string
};

Success.defaultProps = {
  redirect: false,
  redirectUrl: ""
};

export default Success;

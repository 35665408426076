/* eslint-disable import/prefer-default-export */
export const ACCEPTABLE_REFERRERS = new Set([
  "http://google.com",

  "https://connect2.landdb.com",

  "https://sf-connect.landdb.com",

  "https://connect.landdb.com",

  "https://connect-test.agconnections.com",

  "https://connect-web-prod.landdb.com",

  "https://connect-web-staging.landdb.com",

  "https://connect-web-test.landdb.com",

  "https://connect-prod-okta.landdb.com",

  "https://connect-test-okta.landdb.com",

  "https://app.landdb.com",

  "https://staging-app.landdb.com",

  "https://test-app.landdb.com",

  "https://dev-app.landdb.com",

  "https://operations.landdb.com",

  "https://www.landdb.com",

  "https://staging.landdb.com",

  "https://test.landdb.com",

  "https://dev.landdb.com",

  "https://ops.landdb.com",

  "https://staging-ops.landdb.com",

  "https://test-ops.landdb.com",

  "https://dev-ops.landdb.com",

  "https://growerlink.landdb.com",

  "https://growerlink-test.landdb.com",

  "https://growerlink-dev.landdb.com",

  "https://www.landdbmobile.com",

  "https://test.landdbmobile.com",

  "https://dev.landdbmobile.com",

  "https://login.landdb.com",

  "https://dev-login.landdb.com",

  "https://eci.agconnections.com",

  "https://eci-staging.agconnections.com",

  "https://eci-test.agconnections.com",

  "https://eci-dev.agconnections.com",

  "https://gm360-reports-prod.agconnections.com",

  "https://gm360-reports-staging.agconnections.com",

  "https://gm360-reports-test.agconnections.com",

  "https://gm360-reports-dev.agconnections.com",

  "https://ip360-dev.agconnections.com",

  "https://ip360.agconnections.com",

  "https://sf.landdb.com",

  "https://sf-test.landdb.com",

  "https://bizrules-experimental.landdb.com",

  "https://sustainability.landdb.com",

  "https://auth.landdb.com/",

  "Desktop"
]);

import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Container, Button } from "reactstrap";

const EULA_COPY =
  "AG CONNECTIONS\n" +
  "SOFTWARE LICENSE AND TERMS OF SERVICE AGREEMENT\n" +
  "This software license and terms of service agreement (the “Agreement”) governs your use of Ag Connections, Inc. (“Licensor”) software and services, and your use is subject to the condition that you agree to these terms.  If you (i) install or use Land.db, Hand.db, the Land.db viewer application, or any other Licensor software, including any updates or upgrades that Licensor provides to you (“Software”), (ii) use (including accessing or viewing data) or contribute data to any online service operated by or on behalf of Licensor (each a “Service,” collectively, the “Services”), or (iii) click “I agree” or take any other affirmative action indicating your acceptance of this Agreement, then you have agreed to these terms.  If you are an agent or employee of the intended licensee or user, you individually represent and warrant to Licensor that you are authorized to bind that party to this Agreement.  If you do not agree to this Agreement, then do not use any of Licensor’s software or services.\n" +
  "1.\tLicense Grant.  If you are agreeing to these terms in connection with the installation or use of Software, then Licensor hereby grants to you a personal, limited, non-exclusive, non-transferable, non-sublicensable, non-assignable term license to use a single copy of the Software for your internal business purposes.  If you are agreeing to these terms in connection with your use one or more of the Services, then Licensor hereby grants to you a personal, limited, non-exclusive, non-transferable, non-assignable term right to use that/those particular Service(s).  All rights to the Software and Services not specifically granted under this Agreement are reserved to Licensor.  All rights granted in this paragraph are subject to the terms and conditions of this Agreement.  You expressly agree that you are only a licensee, and not an owner of a copy, of the Software; Licensor owns any and all copies of the Software, whether or not in your possession.\n" +
  "2.\tTerms Applicable to Particular Services.  Additional terms apply to your use of certain Services, including but not limited to the Farm By Phone™ Service.  These additional terms are available on the web sites governing those Services.  Licensor may amend those terms at any time by posting the amended terms to the applicable web site.  Your agree that your use of any Service after amended terms are posted indicates your acceptance of those amended terms for such Service.\n" +
  "3.\tRestrictions and Use Limitations.\n" +
  "(a)\tTechnical Requirements.  You shall comply with the minimum technical and connectivity requirements for use of the Software and Services.  These requirements are available either in the documentation associated with the Software and Services or on Licensor’s web site.  If the requirements are not available in those places, then Licensor shall provide them to you upon request.  Licensor may change the requirements in connection with updates to or new versions of the Software or Services, and you are solely responsible for complying with the new requirements.  Licensor is not responsible for any of your costs associated with purchasing new equipment, software, or services that may be required for you to comply with new requirements.\n" +
  "(b)\tBackup Copy.  You may make a single copy of the Software for backup purposes.\n" +
  "(c)\tNo Reverse Engineering or Modifications.  You shall not reverse engineer, decompile, or otherwise attempt to discover the source code of the Software or Services. You shall not modify the Software or Services (including renaming of any electronic files) or combine the Software or Services with any other software or services (except as expressly permitted by the documentation or technical requirements for any of the Software or Services).\n" +
  "(d)\tCopyright Notices.  You shall not remove, modify, overprint or otherwise obscure any patent, trademark, service mark or copyright notices associated with the Software or Services.\n" +
  "(e)\tExport.  You shall not export the Software or Services or use them in any manner prohibited by any applicable laws or regulations.\n" +
  "4.\tModifications to Services.  Licensor may modify or discontinue any of the Services with or without notice to you.  Licensor is not liable to you or any third party should Licensor exercise its right to modify or discontinue the Services.\n" +
  "5.\tCustomer Responsibilities.  You are responsible for the contents of your transmissions through the Services.  Licensor may take any action with respect to the Services that Licensor deems necessary or appropriate if Licensor believes you or your information may create liability for Licensor, compromise or disrupt the Services for you or other customers, or cause Licensor to lose (in whole or in part) the services of Licensor’s bandwidth or other suppliers. You shall not use the Services to transmit any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene or otherwise objectionable material of any kind or nature. You shall (a) obtain and pay for all equipment and third-party services (e.g., Internet access, phone service, SMS, and email service) required for you to access and use the Services; (b) maintain the security of your Customer identification and other confidential information relating to your Services account; and (c) be responsible for all charges resulting from use of your Services account, including unauthorized use prior to your notifying Licensor of such use and taking steps to prevent its further occurrence. \n" +
  "6.\tRoyalties and Fees.  Your rights pursuant to Section 1 above are subject to your payment of all applicable royalties and fees for the Software and Services, in accordance with Licensor’s then-current terms.\n" +
  "7.\tTerm and Termination.  This Agreement and your rights to use Software and/or Services, as the case may be, remains in effect until the earliest to occur of:  (i) you elect to discontinue all use of the Software and Services, and you destroy all copies in your possession, (ii) you fail to pay any applicable fees or royalties relating to the use of the Software and/or Services when due, or (iii) you otherwise breach this Agreement.  You acknowledge that the Software may require registration or renewal codes provided by Licensor to be periodically entered into the Software and that if you have not entered those codes prior to the expiration date, the Software will cease to work properly.\n" +
  "8.\tDisclaimers and Limitations of Liability.\n" +
  '(a)\tWarranty Disclaimer.  EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN LICENSOR AND YOU, LICENSOR IS PROVIDING THE SOFTWARE AND SERVICES TO YOU "AS IS" WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND.  LICENSOR HEREBY DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SOFTWARE OR SERVICES OR THE FUNCTIONALITY, PERFORMANCE OR RESULTS OF USE THEREOF. WITHOUT LIMITING THE FOREGOING, LICENSOR DISCLAIMS ANY WARRANTY THAT THE SOFTWARE OR SERVICES PROVIDED TO YOU OR THE OPERATION THEREOF IS OR WILL BE ACCURATE, ERROR-FREE OR UNINTERRUPTED. LICENSOR DISCLAIMS ANY AND ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR ANY PARTICULAR PURPOSE OR ANY WARRANTY ARISING BY USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE.\n' +
  "(b)\tDisclaimer of Consequential Damages.  LICENSOR AND ITS SUPPLIERS HAVE NO LIABILITY WITH RESPECT TO THE OBLIGATIONS UNDER THIS AGREEMENT OR OTHERWISE TO YOU FOR INDIRECT, CONSEQUENTIAL, EXEMPLARY, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THESE LIMITATIONS APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.\n" +
  "(c)\tLimitation of Liability.  LICENSOR’S TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY REASON AND UPON ANY CAUSE OF ACTION INCLUDING WITHOUT LIMITATION, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATIONS, AND OTHER TORTS, IS LIMITED TO THE GREATER OF (i) $500 OR (ii) THE FEES OR ROYALTIES PAID BY YOU DURING THE PRECEDING 12 MONTHS FOR THE SOFTWARE OR SERVICE GIVING RISE TO THE CLAIM.  THESE LIMITATIONS APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.\n" +
  "(d)\tData Errors.  Without in any way limiting the preceding paragraphs, you acknowledge that Licensor has no responsibility or liability for any erroneous data that you have relied on in connection with your use of the Software and Services, regardless of whether such erroneous data was introduced by a defect on the Software or Services, by you, by a third party, or otherwise.\n" +
  "(e)\tSavings Clause.  Some states or jurisdictions do not allow the exclusion of implied warranties or limitations on how long an implied warranty may last, so the above limitations may not apply to you. If Kentucky law is not held to apply to this Agreement for any reason, then in jurisdictions where warranties, guarantees, representations, and/or conditions of any type may not be disclaimed, any such warranty, guarantee, representation and/or warranty is: (i) hereby limited to the period of either (x) 30 days from the installation of the Software or first use of the Service, as the case may be, or (y) the shortest period allowed by law in the applicable jurisdiction if a 30 day limitation would be unenforceable; and (ii) Licensor's sole liability for any breach of any such warranty, guarantee, representation, and/or condition is to provide you with a corrected version of the defective Software or Service.\n" +
  "9.\tGeneral.\n" +
  "(a)\tForce Majeure.  “Force Majeure Event” means any act or event that (a) prevents Licensor from performing its obligations or satisfying a condition to your obligations under this Agreement, (b) is beyond the reasonable control of and not the fault of Licensor, and (c) Licensor has not, through commercially reasonable efforts, been able to avoid or overcome.  If a Force Majeure Event occurs, Licensor is excused from the performance thereby prevented and from satisfying any conditions precedent to your performance that cannot be satisfied, in each case to the extent limited or prevented by the Force Majeure Event.  When the Licensor is able to resume its performance or satisfy the conditions precedent to the other party’s obligations, Licensor shall immediately resume performance under this Agreement.  The relief offered by this paragraph is the exclusive remedy available to you with respect to a Force Majeure Event.\n" +
  "(b)\tAssignment.  You shall not assign any of your rights under this Agreement, except with the prior written consent of Licensor.  Any purported assignment of rights in violation of this Section is void.\n" +
  "(c)\tGoverning Law; Venue.  The laws of the State of Kentucky (without giving effect to its conflict of laws principles) govern all matters arising out of or relating to this Agreement and the transactions it contemplates, including, without limitation, its interpretation, construction, performance, and enforcement. The parties expressly agree that neither the Uniform Computer Information Transactions Act, or any state’s version thereof, nor the United Nations Convention on Contracts for the International Sale of Goods apply to this Agreement.  The parties shall bring any claims or actions regarding or arising out of this Agreement exclusively in a court of competent jurisdiction sitting in Calloway County, Kentucky (or, if a federal action, in the Paducah Division of the United States District Court for the Western District of Kentucky), and each party to this Agreement submits to the jurisdiction of such courts for the purposes of all legal actions and proceedings arising out of or relating to this Agreement. Each party waives, to the fullest extent permitted by law, any objection that it may now or later have to (i) the laying of venue of any legal action or proceeding arising out of or relating to this Agreement brought in any state court of competent jurisdiction sitting in Calloway County, Kentucky, or with respect to a federal action, in the Paducah Division of the United States District Court for the Western District of Kentucky; and (ii) any claim that any action or proceeding brought in any such court has been brought in an inconvenient forum.  \n" +
  "(d)\tEntire Agreement.  This Agreement constitutes the final and complete Agreement between you and Licensor with respect to the matters contained herein.  All prior and contemporaneous negotiations and agreements between the parties on the matters contained in this Agreement are expressly merged into and superseded by this Agreement. The provisions of this Agreement cannot be explained, supplemented or qualified through evidence of trade usage or a prior course of dealings.\n" +
  "(e)\tSeverability.  If any provision of this Agreement is determined to be invalid, illegal or unenforceable, the remaining provisions of this Agreement will remain in full force, if the essential terms and conditions of this Agreement for each party remain valid, binding, and enforceable.\n" +
  "(f)\tAmendments.  Except for Licensor’s rights to make modifications as expressly set forth elsewhere in this Agreement, the parties can amend this Agreement only by a written agreement of the parties that identifies itself as an amendment to this Agreement.  In addition, Licensor may amend this Agreement at any time by (a) posting the amended agreement on Licensor’s web site, or (b) by sending information regarding the amendment to the email address you provide to Licensor.\n" +
  "(g)\tSurvival of Certain Provisions.  The provisions contained in Sections 3 (except for 3(b)), 8, and 9, in addition to any other provision that, by its terms, is intended to survive the expiration or termination of this Agreement, will survive the expiration or termination of this Agreement.\n" +
  "(h)\tRecovery of Litigation Costs.  If any legal action or other proceeding is brought for the enforcement of this Agreement, or because of an alleged dispute, breach, default or misrepresentation in connection with any of the provisions of this Agreement, the successful or prevailing party or parties are entitled to recover reasonable attorneys’ fees and other costs incurred in that action or proceeding, in addition to any other relief to which it or they may be entitled.\n" +
  "\n";

const EULA = ({ location }) => {
  const { referrer, environment } = queryString.parse(location.search);

  const getBackButton = link => {
    return (
      <Button
        color="danger"
        size="lg"
        onClick={() => {
          if (link === "https://auth.landdb.com/") {
            if (environment && environment === "Desktop") {
              window.location.href = "/register?referrer=Desktop";
            } else {
              window.location.href =
                "/register?referrer=https://auth.landdb.com/";
            }
          } else if (link === "Desktop") {
            window.location.href = "https://auth.landdb.com/?referrer=Desktop";
          } else {
            window.close();
          }
        }}
      >
        Back
      </Button>
    );
  };

  return (
    <Container>
      <div style={{ margin: "25px 0 25px 0" }}>
        <h2>AG CONNECTIONS EULA</h2>
        <div style={{ height: "70vh", overflow: "auto", padding: "10px" }}>
          <p>{EULA_COPY}</p>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {getBackButton(referrer)}
      </div>
    </Container>
  );
};

EULA.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

EULA.defaultProps = {
  location: {
    search: ""
  }
};

export default EULA;

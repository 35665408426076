import React from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import styled from "styled-components";
import queryString from "query-string";
import { Formik } from "formik";
import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Button,
  Container,
  Input,
  Col,
  Row,
  Alert,
  Spinner
} from "reactstrap";

import Success from "../components/Success";

import * as okta from "../services/okta";

import { ACCEPTABLE_REFERRERS } from "../constants";
import logo from "../static/logo.jpg";

const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px 0;
`;

const FormContainer = styled.div`
  padding: 20px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & button:first-child {
    margin-right: 8px;
  }
`;

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails must match")
    .required("Required"),
  password: Yup.string()
    .min(10, "Password must be at least 10 characters long")
    .max(72, "Password cannot exceed 72 characters in length")
    .matches(/^(?=.*[a-z]).+$/, "Password must contain a lowercase letter")
    .matches(/^(?=.*[A-Z]).+$/, "Password must contain a uppercase letter")
    .matches(/^(?=.*\d).+$/, "Password must contain number")
    .matches(
      /^(?=.*["!#$%&’()*+,\-./:;<=>?@[\]^_`{|}~]).+$/,
      "Password must contain a special character"
    )
    .matches(/^(?!.*[\\ ]).+$/, "Invalid Character")
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  eula: Yup.bool().oneOf([true], "Please accept the EULA to continue.")
});

export const verifyReferrer = referrer => {
  return ACCEPTABLE_REFERRERS.has(referrer);
};

export const handleUserRegistration = location => async (values, actions) => {
  actions.setSubmitting(true);

  if (values.email !== values.confirmEmail) {
    actions.setStatus({
      error: "Emails do not match. Please check your email and try again."
    });

    actions.setSubmitting(false);

    return;
  }

  if (values.password !== values.confirmPassword) {
    actions.setStatus({
      error: "Passwords do not match. Please check your password and try again."
    });

    actions.setSubmitting(false);

    return;
  }

  try {
    await okta.createUser({
      profile: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        login: values.email.trim()
      },
      credentials: {
        password: {
          value: values.password.trim()
        }
      }
    });

    const urlParams = queryString.parse(location.search);

    if (urlParams.referrer && verifyReferrer(urlParams.referrer)) {
      setTimeout(() => {
        if (urlParams.referrer === "Desktop") {
          global.window.location.href =
            "https://auth.landdb.com/?referrer=Desktop";
        } else {
          global.window.location.href = urlParams.referrer;
        }
      }, 5000); // redirect in 5 seconds per story
    }

    actions.setStatus({
      success: true,
      redirect: !!urlParams.referrer,
      redirectUrl: urlParams.referrer
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    if (error.response) {
      switch (error.response.data.errorCode) {
        case "E0000001":
          actions.setStatus({
            error: "A user with that email already exists in our system."
          });

          break;

        default:
          actions.setStatus({
            error:
              "Whoops! Something went wrong. Try again or contact support. "
          });

          break;
      }
    } else {
      actions.setStatus({
        error: "Whoops! Something went wrong. Try again or contact support. "
      });
    }
  }

  actions.setSubmitting(false);
};

const getBackButton = referrer => {
  if (referrer && verifyReferrer(referrer)) {
    return (
      <Button
        color="danger"
        onClick={() => {
          if (referrer === "Desktop") {
            window.location.href = "https://auth.landdb.com/?referrer=Desktop";
          } else {
            window.location.href = referrer;
          }
        }}
      >
        Back
      </Button>
    );
  }

  return null;
};

const getEulaLink = referrer => {
  if (referrer === "https://auth.landdb.com/") {
    return <a href="/eula?referrer=https://auth.landdb.com/">EULA</a>;
  }else if (referrer === "Desktop") {
    return (
      <a href="/eula?referrer=https://auth.landdb.com/&environment=Desktop">EULA</a>
    );
  }else{
    return (
      <a target="_blank" href="/eula">EULA</a>
    );
  }
};

const Register = ({ location }) => (
  <Formik
    initialValues={{
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      eula: false
    }}
    initialStatus={{
      error: false,
      success: false
    }}
    validationSchema={RegistrationSchema}
    onSubmit={handleUserRegistration(location)}
    render={({
      isSubmitting,
      isValid,
      status,
      values,
      errors,
      touched,
      handleSubmit,
      handleReset,
      handleBlur,
      handleChange
    }) => {
      const urlParams = queryString.parse(location.search);

      return (
        <Container>
          <InnerContainer style={{padding:0}}>
            {status.success ? (
              <Success redirect={status.redirect} redirectUrl={status.redirectUrl}/>
            ) : (
              <FormContainer>
                <Row>
                  <Col lg={{size:4, offset:4}} sm={12}>
                    <img style={{maxWidth: "300px"}} className="img-fluid mx-auto d-block" src={logo}
                         alt="Ag Connections Logo"
                    />
                  </Col>
                </Row>
                <hr />
                <Row style={{marginBottom: "10px"}}>
                  <Col style={{textAlign: "center"}} lg={12}>Sign up with your email address</Col>
                </Row>
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                  {status.error && (
                    <Row>
                      <Col sm={12}>
                        <Alert color="danger" isOpen fade={false}>
                          {status.error}
                        </Alert>
                      </Col>
                    </Row>
                  )}
                  <FormGroup row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Input id="firstName" name="firstName" type="text" value={values.firstName}
                        onChange={handleChange} onBlur={handleBlur} valid={Boolean(!errors.firstName && values.firstName)}
                        invalid={Boolean(errors.firstName && touched.firstName)} placeholder="First Name"
                      />
                      <FormFeedback invalid={errors.firstName && touched.firstName ? "true" : undefined } >
                        {errors.firstName}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Input id="lastName" name="lastName" type="text" value={values.lastName} onChange={handleChange}
                        onBlur={handleBlur} valid={Boolean(!errors.lastName && values.lastName)}
                        invalid={Boolean(errors.lastName && touched.lastName)} placeholder="Last Name"
                      />
                      <FormFeedback invalid={errors.lastName && touched.lastName ? "true" : undefined}>
                        {errors.lastName}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Input id="email" name="email" type="email" value={values.email} onChange={handleChange}
                        onBlur={handleBlur} valid={Boolean(!errors.email && values.email)}
                        invalid={Boolean(errors.email && touched.email)} placeholder="Email"
                      />
                      <FormFeedback invalid={errors.email && touched.email ? "true" : undefined}>
                        {errors.email}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Input id="confirmEmail" name="confirmEmail"  type="email" value={values.confirmEmail}
                        onChange={handleChange} onBlur={handleBlur}
                        valid={Boolean(!errors.confirmEmail && values.confirmEmail)}
                        invalid={Boolean(errors.confirmEmail && touched.confirmEmail)}
                        placeholder="Confirm Email"
                      />
                      <FormFeedback invalid={errors.confirmEmail && touched.confirmEmail ? "true" : undefined}>
                        {errors.confirmEmail}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Input id="password" name="password" type="password" value={values.password}
                        onChange={handleChange} onBlur={handleBlur} valid={Boolean(!errors.password && values.password)}
                        invalid={Boolean(errors.password && touched.password)} placeholder="Password"
                      />
                      <FormFeedback invalid={errors.password && touched.password ? "true" : undefined}>
                        {errors.password}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Input id="confirmPassword" name="confirmPassword" type="password"
                        value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur}
                        valid={Boolean(!errors.confirmPassword && values.confirmPassword)}
                        invalid={Boolean(errors.confirmPassword && touched.confirmPassword)}
                        placeholder="Confirm Password"
                      />
                      <FormFeedback invalid={errors.confirmPassword && touched.confirmPassword ? "true" : undefined}>
                        {errors.confirmPassword}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <Row className="justify-content-center">
                    <Col lg={5} sm={8}>
                      <Alert color="secondary">
                        <div style={{fontWeight: "bold"}}>Password Requirements</div>
                        Must be at least 10 characters long and include the following:
                        <ul style={{paddingLeft:"20px"}}>
                          <li>One or more capital letters</li>
                          <li>One or more lowercase letters</li>
                          <li>One or more numbers</li>
                          <li>
                            <div>One or more of the following special characters:</div>
                            <div>{'" ! # $ % & ’ ( ) * + , - . / : ; < = > ? @ [] ^ _ ` { | } ~'}</div>
                          </li>
                        </ul>
                      </Alert>
                    </Col>
                  </Row>
                  <FormGroup check row className="text-center">
                    <Label check sm={12}>
                      <Input id="eula" name="eula" type="checkbox" value={values.eula}
                        onChange={handleChange} onBlur={handleBlur}
                        valid={Boolean(!errors.eula && values.eula)}
                        invalid={Boolean(errors.eula && touched.eula)}
                      />
                      {`I have reviewed and agree to the `}
                      {getEulaLink(urlParams.referrer)}
                      {`.`}
                    </Label>
                    <FormFeedback invalid={errors.eula && touched.eula ? "true" : undefined}>
                      {errors.eula}
                    </FormFeedback>
                  </FormGroup>
                  <ButtonContainer>
                    {getBackButton(urlParams.referrer)}
                    <Button type="submit" disabled={!isValid || isSubmitting}
                      color={isValid ? "success" : "secondary"}
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner color="light" size="md" />
                          Loading...
                        </>
                      ) : (
                        "Sign-Up!"
                      )}
                    </Button>
                  </ButtonContainer>
                </Form>
              </FormContainer>
            )}
          </InnerContainer>
        </Container>
      );
    }}
  />
);

Register.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

Register.defaultProps = {
  location: {
    search: ""
  }
};

export default Register;

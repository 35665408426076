import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import Register from "./pages/Register";
import EULA from "./pages/EULA";
import Instructions from "./pages/Instructions";

ReactDOM.render(
  <Router>
    <Route exact path="/register" render={props => <Register {...props} />} />
    <Route exact path="/eula" render={props => <EULA {...props} />} />
    <Route
      exact
      path="/instructions"
      render={props => <Instructions {...props} />}
    />
  </Router>,
  global.document.getElementById("root")
);

import React from "react";
import queryString from "query-string";
import { Container, Row, Col, Button } from "reactstrap";
import logo from "../static/logo.jpg";
import widget1 from "../static/widget1.jpg";
import widget2 from "../static/widget2.jpg"
import oneIcon from "../static/OneIcon.svg";
import twoIcon from "../static/TwoIcon.svg";


const Instructions = ({ location }) => {
  const { referrer } = queryString.parse(location.search);

  const boxStyle = { justifyContent: "center", borderColor: "black",
    border: "2px solid", margin: "15px", padding:"20px", wordWrap: "break-word"};
  const headerStyle = {fontSize: "1.5em", textAlign: "center", margin: "1em"};

  return (
    <Container>
      <Row style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <div style={headerStyle}>Welcome to the new sign on experience</div>
      </Row>
      <Row style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Col lg={{ size: 6 }}>
          Please review the instructions below to get help signing in. If you
          experience an issue, please contact Ag Connections Support.
        </Col>
      </Row>
      <Row style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Col lg={{ size: 8 }}>
          <hr />
        </Col>
      </Row>
      <Row>
        {referrer === "Desktop" && (
          <a href="https://auth.landdb.com/?referrer=Desktop"
             style={{ textDecoration: "underline", marginLeft: "20px" }}
          >
            {`<< Back`}
          </a>
        )}
      </Row>
      <Row style={{ width: "100%", height: "100%" }}>
        <Col style={headerStyle}>
          Sign in options for Existing Users
        </Col>
      </Row>
      <Row>
        <Col lg={6} style={{wordWrap: "break-word"}}>
          <Row style={{justifyContent: "center", margin: "auto"}}>
            <Col style={boxStyle} lg={8}>
              <img style={{marginRight: "10px"}}  width={24} src={oneIcon} alt="1"/>
              Current Land.db users, click here.
            </Col>
          </Row>
          <Row style={{justifyContent: "center", margin: "auto"}}>
            <Col style={boxStyle} lg={8}>
              <Row>
                <Col xs={1} style={{marginRight: "10px"}}>
                  <img width={24} src={twoIcon} alt="2" />
                </Col>
                <Col xs={10}>
                  If you create an account using the "Sign up" Button below, please enter your
                  email address and password, then press the "Sign In" button.
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={2} xs={12} style={{margin: "auto", justifyContent: "center", display: "flex"}}>
          <img style={{minWidth: "325px", maxWidth: "350px", width: "100%", height: "100%"}} src={widget1} alt="widget1"/>
        </Col>
        <Col lg={1} xs={0} />
      </Row>
      <Row style={{ width: "100%", height: "100%", justifyContent: "center" }}>
        <div style={headerStyle}>Sign in options for New Users</div>
      </Row>
      <Row>
        <Col lg={6} style={{wordWrap: "break-word"}}>
          <Row style={{justifyContent: "center", margin: "auto"}}>
            <Col style={boxStyle} lg={8}>
              <Row>
                <Col xs={1} style={{marginRight: "10px"}}>
                  <img width={24} src={oneIcon} alt="1" />
                </Col>
                <Col xs={10}>
                  New Land.db users can create an account
                  by using existing Google or Microsoft credentials.
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{justifyContent: "center", margin: "auto"}}>
            <Col style={boxStyle} lg={8}>
              <Row>
                <Col xs={1} style={{marginRight: "10px"}}>
                  <img width={24} src={twoIcon} alt="2" />
                </Col>
                <Col xs={10}>
                  New Land.db users click "Sign up" to create an account.
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={2} xs={12} style={{margin: "auto", justifyContent: "center", display: "flex"}}>
          <img style={{minWidth: "325px", maxWidth: "350px", width: "100%", height: "100%"}} src={widget2} alt="widget2"/>
        </Col>
        <Col lg={1} xs={0} />
      </Row>
      <Row style={{marginTop: "2em", display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Col lg={{ size: 8 }}>
          <hr />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Col style={{ whiteSpace: "nowrap", textAlign: "center" }}>
          Ag Connections Support
        </Col>
        <Col style={{ whiteSpace: "nowrap", textAlign: "center" }}>
          Phone: 866-552-6332
        </Col>
        <Col style={{ whiteSpace: "nowrap", textAlign: "center" }}>
          Email: support@agconnections.com
        </Col>
      </Row>
      <Row style={{marginTop: "2em", display: "flex", justifyContent: "center", textAlign: "center"}}>
        <img style={{ width: "200px", height: "100%" }} onMouseDown={e => e.preventDefault()}
          onContextMenu={e => {e.preventDefault();}} src={logo} alt="logo"
        />
      </Row>
      <Row style={{marginTop: "2em", display: "flex", justifyContent: "center", textAlign: "center"}}>
        {referrer === "Desktop" && (
          <Button color="danger" size="lg" onClick={() => {
              global.window.location.href = "https://auth.landdb.com/?referrer=Desktop";}}
          >
            Back
          </Button>
        )}
      </Row>
    </Container>
  );
};

export default Instructions;
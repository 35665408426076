/* eslint-disable import/prefer-default-export */
import axios from "axios";

export const createUser = async payload => {
  const { data } = await axios.post(
    process.env.REACT_APP_OKTA_PROXY_URL,
    JSON.stringify(payload)
  );

  return data;
};
